import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { collection, doc, setDoc } from "firebase/firestore";
import { ethers } from "ethers";
import { affiliationResolverContractAbi } from "./data/affiliationResolver";
import { affiliationServiceContractAbi } from "./data/affiliationService";
import { affiliationServiceTokenContractAbi } from "./data/affiliationServiceToken";
import { affiliationServiceConsumableContractAbi } from "./data/affiliationServiceConsumable";
import { affiliationServiceTokenObject } from './data/affiliationServiceTokenObject';
import { affiliationServiceConsumableObject } from './data/affiliationServiceConsumableObject';

function Store(props) {

  const [memberStart, setMemberStart] = useState(0);
  const [members, setMembers] = useState(100);
  const [service, setService] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [tokens, setTokens] = useState(0);
  const [memberLimit, setMemberLimit] = useState(0);
  const [usersLimit, setUsersLimit] = useState(0);
  const [notificationsLimit, setNotificationsLimit] = useState(0);
  const [startValue, setStartValue] = useState(0);
  const [store, setStore] = useState({});
  const [services, setServices] = useState({});
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);
  const [membersMessage, setMembersMessage] = useState('');
  const [storeMessage, setStoreMessage] = useState('');

  const [wallet] = useOutletContext();
  const params = useParams();

  const registerService = async (service) => {
    setLoadingServices(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const factory = new ethers.ContractFactory(service.abi, service.bytecode, wallet);
      const serviceContract = await (await factory.deploy(env.RESOLVER_ADDRESS, ethers.utils.formatBytes32String(params.store))).deployed()

      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      await (await contract.setService(ethers.utils.formatBytes32String(params.store), ethers.utils.formatBytes32String(service.key), serviceContract.address)).wait();

      const localStore = store;
      localStore.services.push(service.key);
      setDoc(doc(collection(props.db, 'stores'), params.store), localStore);
      setStore(localStore);

      const currentServices = services;
      currentServices[service.key].address = serviceContract.address;
      setServices(currentServices);
    }

    setLoadingServices(false);
  };

  const removeService = async (service) => {
    setLoadingServices(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      await (await contract.removeService(ethers.utils.formatBytes32String(params.store), ethers.utils.formatBytes32String(service.key))).wait();

      const localStore = store;
      localStore.services.splice(localStore.services.findIndex((o) => o === service.key), 1);
      for (const card in localStore.cards) {
        if (service.key in localStore.cards[card].services) {
          delete localStore.cards[card].services[service.key];
          if (Object.keys(localStore.cards[card].services).length === 0) {
            delete localStore.cards[card];
          }
        }
      }
      setDoc(doc(collection(props.db, 'stores'), params.store), localStore);
      setStore(localStore);

      const currentServices = services;
      currentServices[service.key].address = ethers.constants.AddressZero;
      setServices(currentServices);
    }

    setLoadingServices(false);
  };

  const registerMembers = async () => {
    if (!service) {
      setMembersMessage('Selezionare almeno un servizio.');
      return;
    }

    setLoadingMembers(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const chunkSize = 50;
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      const availableMembers = await contract.getAvailableMembers(ethers.utils.formatBytes32String(params.store));

      const memberList = [];
      for (let i = parseInt(memberStart); i < parseInt(memberStart) + parseInt(members); i++) {
        memberList.push(ethers.utils.formatBytes32String(params.store + i.toString().padStart(9, '0')))
      }

      for (let i = 0; i < memberList.length; i += chunkSize) {
        const memberSlicedList = memberList.slice(i, i + chunkSize);
        if (!memberSlicedList.every(x => availableMembers.includes(x))) {
          await (await contract.setAvailableMemberList(ethers.utils.formatBytes32String(params.store), memberSlicedList)).wait();
        }
        const serviceContract = new ethers.Contract(services[service].address, affiliationServiceContractAbi, wallet);
        await (await serviceContract.registerList(memberSlicedList)).wait();

        const localStore = store;
        for (let j = 0; j < memberSlicedList.length; j++) {
          if (!(ethers.utils.parseBytes32String(memberSlicedList[j]) in localStore.cards)) {
            localStore.cards[ethers.utils.parseBytes32String(memberSlicedList[j])] = {
              code: ethers.utils.parseBytes32String(memberSlicedList[j]),
              secret: '' + Math.floor(Math.random()*10) + Math.floor(Math.random()*10) + Math.floor(Math.random()*10),
              used: type !== 'digital',
              services: {},
            };
          }
          localStore.cards[ethers.utils.parseBytes32String(memberSlicedList[j])].services[service] = services[service].address;
          if (startValue > 0) {
            const serviceDetailContract = new ethers.Contract(services[service].address, services[service].abi, wallet);
            await (await serviceDetailContract.add(memberSlicedList[j], startValue)).wait();
          }
        }
        setDoc(doc(collection(props.db, 'stores'), params.store), localStore);
        setStore(localStore);
      }

      setMemberStart(0);
      setMembers(100);
      setService('');
      setStartValue(0);
    }

    setLoadingMembers(false);
  };

  const registerStore = async () => {
    if (!name) {
      setStoreMessage('Nome è richiesto.');
      return;
    }
    if (!tokens) {
      setStoreMessage('Nome è richiesto.');
      return;
    }
    if (!memberLimit) {
      setStoreMessage('Limite Tessere è richiesta.');
      return;
    }
    if (!usersLimit) {
      setStoreMessage('Limite Utenze è richiesta.');
      return;
    }
    if (!notificationsLimit) {
      setStoreMessage('Limite Notifiche è richiesta.');
      return;
    }

    setLoadingStore(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      await (await contract.setData(ethers.utils.formatBytes32String(params.store), JSON.stringify({name: name, whiteLabel: false}))).wait();
      await (await contract.setMemberLimit(ethers.utils.formatBytes32String(params.store), memberLimit)).wait();

      const localStore = store;
      localStore.name = name;
      localStore.tokens = tokens;
      localStore.users.limit = usersLimit;
      localStore.notifications.limit = notificationsLimit;
      setDoc(doc(collection(props.db, 'stores'), params.store), localStore);
      setStore(localStore);

      setName(name);
      setTokens(tokens);
      setMemberLimit(memberLimit);
    }

    setLoadingStore(false);
  };

  useEffect(() => {
    const initData = async () => {
      setLoadingServices(true);
      setLoadingMembers(true);
      setLoadingStore(true);
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      const currentMemberLimit = await contract.getMemberLimit(ethers.utils.formatBytes32String(params.store));
      const currentStore = await fetch(env.AUTH0_API_SERVER+"store-info/"+params.store, {
        headers: {
          "Content-Type":"application/json",
        },
      }).then((res) => res.json());
      const serviceList = await fetch(env.AUTH0_API_SERVER+"services", {
        headers: {
          "Content-Type":"application/json",
        },
      }).then((res) => res.json());
      const currentServices = {};
      for (let i=0; i<serviceList.length; i++) {
        const abi = serviceList[i].consumable ? affiliationServiceConsumableContractAbi : affiliationServiceTokenContractAbi;
        const bytecode = serviceList[i].consumable ? affiliationServiceConsumableObject : affiliationServiceTokenObject;
        const address = await contract.getService(ethers.utils.formatBytes32String(params.store), ethers.utils.formatBytes32String(serviceList[i].code))
        currentServices[serviceList[i].code] = {
          label: serviceList[i].name,
          key: serviceList[i].code,
          address: address,
          abi: abi,
          bytecode: bytecode,
        };
      }
      setName(currentStore.name);
      setTokens(currentStore.tokens);
      setMemberLimit(parseInt(currentMemberLimit));
      setUsersLimit(currentStore.users.limit);
      setNotificationsLimit(currentStore.notifications.limit);
      setStore(currentStore);
      setServices(currentServices);
      setLoadingServices(false);
      setLoadingMembers(false);
      setLoadingStore(false);
    };
    initData();
  }, [params, wallet]);

  return (
    <section id="store">
      <div className="affiliation-section">
        <h2 className="affiliation-title">{store.name}</h2>
        <div className="mt-5">
          <h2 className="affiliation-subtitle">Servizi</h2>
          <div className="mt-5">
            {!loadingServices && <table className="table">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Indirizzo</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(services).map((v, k) => (
                  <tr key={k}>
                    <td>{services[v].label}</td>
                    {parseInt(services[v].address, 16) > 0 && <td>
                      <button className="btn btn-sm item-button-danger" onClick={() => removeService(services[v])}>Elimina</button>
                    </td>}
                    {parseInt(services[v].address, 16) === 0 && <td>
                      <button className="btn btn-sm item-button-success" onClick={() => registerService(services[v])}>Registra</button>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </table>}
              {loadingServices && <div className="text-center mt-4"><div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div></div>}
          </div>
        </div>
        <div className="mt-5">
          <h2 className="affiliation-subtitle">Registra Tessere</h2>
          <div className="mt-5">
            <label className="mt-2">Tessera Iniziale</label>
            <input className="affiliation-input" disabled={loadingMembers} type="number" min="0" step="50" value={memberStart} onChange={e => {setMemberStart(e.target.value); setMembersMessage('');}} required />
            <label className="mt-2">Quantità</label>
            <input className="affiliation-input" disabled={loadingMembers} type="number" min="50" step="50" value={members} onChange={e => {setMembers(e.target.value); setMembersMessage('');}} required />
            <label className="mt-2">Servizio</label>
            <select className="affiliation-input" value={service} onChange={e => {setService(e.target.value); setMembersMessage('');}} required >
              <option value=""></option>
              {Object.values(services).filter((x) => parseInt(services[x.key].address, 16) > 0).map((v, k) => (
                <option key={k} value={v.key}>{v.label}</option>
              ))}
            </select>
            <label className="mt-2">Tipo Tessera</label>
            <select className="affiliation-input" value={type} onChange={e => {setType(e.target.value); setMembersMessage('');}} required >
              <option value="">Fisica</option>
              <option value="digital">Digitale</option>
            </select>
            <label className="mt-2">Valore Iniziale</label>
            <input className="affiliation-input" disabled={loadingMembers} type="number" min="0" step="1" value={startValue} onChange={e => {setStartValue(e.target.value); setMembersMessage('');}} required />
            <div className="text-center mt-4">
              <p>{membersMessage}</p>
              {!loadingMembers && <button className="btn btn-lg item-button-success mt-3" onClick={ () => registerMembers() }>Registra</button>}
              {loadingMembers && <div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div>}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h2 className="affiliation-subtitle">Modifica Negozio</h2>
          <div className="mt-5">
            <label className="mt-2">Nome</label>
            <input className="affiliation-input" disabled={loadingStore} type="text" value={name} onChange={e => {setName(e.target.value); setStoreMessage('');}} required />
            <label className="mt-2">Tokens</label>
            <input className="affiliation-input" disabled={loadingStore} type="text" value={tokens} onChange={e => {setName(e.target.value); setStoreMessage('');}} required />
            <label className="mt-2">Limite Tessere</label>
            <input className="affiliation-input" disabled={loadingStore} type="text" value={memberLimit} onChange={e => {setMemberLimit(e.target.value); setStoreMessage('');}} required />
            <label className="mt-2">Limite Utenze</label>
            <input className="affiliation-input" disabled={loadingStore} type="text" value={usersLimit} onChange={e => {setUsersLimit(e.target.value); setStoreMessage('');}} required />
            <label className="mt-2">Limite Notifiche</label>
            <input className="affiliation-input" disabled={loadingStore} type="text" value={notificationsLimit} onChange={e => {setNotificationsLimit(e.target.value); setStoreMessage('');}} required />
            <div className="text-center mt-4">
              <p>{storeMessage}</p>
              {!loadingStore && <button className="btn btn-lg item-button-success mt-3" onClick={ () => registerStore() }>Registra</button>}
              {loadingStore && <div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Store;
