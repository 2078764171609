import { useState, useEffect } from "react";
import env from "react-dotenv";
import { Outlet, useLocation } from "react-router-dom";
import { ethers } from "ethers";

function Layout() {

  const [wallet, setWallet] = useState({address: '', privateKey: ''});
  const [key, setKey] = useState('');
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const connect = async (k) => {
    setLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(env.DEFAULT_RPC_URL);
    setKey(k);
    try {
      const currentWallet = new ethers.Wallet(k, provider);
      setWallet(currentWallet);
    } catch (e) {
      setWallet({address: '', privateKey: ''});
    }
    window.sessionStorage.setItem('walletkey', k);
    setLoading(false);
  };

  const disconnect = async () => {
    setLoading(true);
    setKey('');
    setWallet({address: '', privateKey: ''});
    window.sessionStorage.removeItem('walletkey');
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const currentKey = window.sessionStorage.getItem('walletkey') ?? '';
      setKey(currentKey);
      const provider = new ethers.providers.JsonRpcProvider(env.DEFAULT_RPC_URL);
      try {
        const currentWallet = new ethers.Wallet(currentKey, provider);
        setWallet(currentWallet);
      } catch (e) {
        setWallet({address: '', privateKey: ''});
      }
      setLoading(false);
    };
    initData();
  }, []);

  return (
    <section id="layout">
      <header className="header text-end">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <div className="navbar-brand">
              {wallet.address !== env.OWNER_ADDRESS && <button className="btn auth-button" onClick={() => connect(key)} >log in</button>}
              {wallet.address === env.OWNER_ADDRESS && <button className="btn auth-button" onClick={() => disconnect()} >log out</button>}
              {wallet.address !== env.OWNER_ADDRESS && <input className="ms-3" type="text" value={key} onChange={e => {setKey(e.target.value);}} />}
            </div>
            {wallet.address === env.OWNER_ADDRESS && <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </span>
            </button>}
            {wallet.address === env.OWNER_ADDRESS && <div className="collapse navbar-collapse navabar-collapse-md justify-content-end" id="navbarContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className={location.pathname === '/' ? "nav-link active" : "nav-link"} href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className={location.pathname === '/register' ? "nav-link active" : "nav-link"} href="/register">Registra</a>
                </li>
              </ul>
            </div>}
          </div>
        </nav>
      </header>
      <main className="main container">
        {!loading && wallet.address === env.OWNER_ADDRESS && <Outlet context={[wallet]} />}
        {loading && <div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div>}
      </main>
      <footer className="footer">
      </footer>
    </section>
  );
}

export default Layout;
