import env from "react-dotenv";
import Layout from './Layout';
import Home from './Home';
import Register from './Register';
import Store from './Store';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

function App() {

  const firebaseProject = initializeApp({
    apiKey:  env.FIREBASE_KEY,
    authDomain: env.FIREBASE_PROJECT + ".firebaseapp.com",
    projectId: env.FIREBASE_PROJECT,
    storageBucket: env.FIREBASE_PROJECT + ".appspot.com",
    messagingSenderId: env.FIREBASE_SENDER,
    appId: env.FIREBASE_APP,
  });
  const db = getFirestore(firebaseProject);

  return (
    <section id="app">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home db={db} />} />
            <Route path="/register" element={<Register db={db} />} />
            <Route path="/store/:store" element={<Store db={db} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </section>
  );
}

export default App;
