export const affiliationResolverContractAbi = [
			{
				"inputs": [],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "previousOwner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "OwnershipTransferred",
				"type": "event"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "address",
						"name": "vendor",
						"type": "address"
					}
				],
				"name": "addStore",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getAvailableMembers",
				"outputs": [
					{
						"internalType": "bytes32[]",
						"name": "",
						"type": "bytes32[]"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getData",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "member",
						"type": "bytes32"
					}
				],
				"name": "getMemberInfo",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getMemberLimit",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "serviceName",
						"type": "bytes32"
					}
				],
				"name": "getService",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getServiceList",
				"outputs": [
					{
						"internalType": "address[]",
						"name": "",
						"type": "address[]"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getStores",
				"outputs": [
					{
						"internalType": "bytes32[]",
						"name": "",
						"type": "bytes32[]"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getTotalMembers",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getTotalUsers",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getUserLimit",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "getVendor",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "member",
						"type": "bytes32"
					}
				],
				"name": "isMemberAvailable",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "owner",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "member",
						"type": "bytes32"
					}
				],
				"name": "removeAvailableMember",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32[]",
						"name": "memberList",
						"type": "bytes32[]"
					}
				],
				"name": "removeAvailableMemberList",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "serviceName",
						"type": "bytes32"
					}
				],
				"name": "removeService",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "removeStore",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "renounceOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					}
				],
				"name": "requestUser",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "member",
						"type": "bytes32"
					}
				],
				"name": "setAvailableMember",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32[]",
						"name": "memberList",
						"type": "bytes32[]"
					}
				],
				"name": "setAvailableMemberList",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "string",
						"name": "data",
						"type": "string"
					}
				],
				"name": "setData",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "uint256",
						"name": "memberLimit",
						"type": "uint256"
					}
				],
				"name": "setMemberLimit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "serviceName",
						"type": "bytes32"
					},
					{
						"internalType": "address",
						"name": "serviceAddress",
						"type": "address"
					}
				],
				"name": "setService",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "uint256",
						"name": "totalUsers",
						"type": "uint256"
					}
				],
				"name": "setTotalUsers",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "uint256",
						"name": "userLimit",
						"type": "uint256"
					}
				],
				"name": "setUserLimit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "store",
						"type": "bytes32"
					},
					{
						"internalType": "address",
						"name": "vendor",
						"type": "address"
					}
				],
				"name": "setVendor",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"name": "storeList",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"name": "stores",
				"outputs": [
					{
						"internalType": "address",
						"name": "vendor",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "data",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "memberLimit",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "userLimit",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalUsers",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "transferOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			}
		];
