import { useState } from "react";
import env from "react-dotenv";
import { useOutletContext } from "react-router-dom";
import { collection, doc, setDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { ethers } from "ethers";
import { affiliationResolverContractAbi } from "./data/affiliationResolver";

function Register(props) {

  const [description, setDescription] = useState('');
  const [memberLimit, setMemberLimit] = useState(1000);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [wallet] = useOutletContext();

  const register = async () => {
    if (!description) {
      setMessage('Nome Negozio è richiesto.');
      return;
    }
    if (!email) {
      setMessage('Email è richiesta.');
      return;
    }

    const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
    const storeList = await contract.getStores();
    let store = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
    while (storeList.includes(ethers.utils.formatBytes32String(store))) {
      store = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
    }

    setLoading(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const vendorWallet = ethers.Wallet.createRandom();

      await (await contract.addStore(ethers.utils.formatBytes32String(store), vendorWallet.address)).wait();
      await (await contract.setMemberLimit(ethers.utils.formatBytes32String(store), memberLimit)).wait();
      await (await contract.setData(ethers.utils.formatBytes32String(store), JSON.stringify({name: description, whiteLabel: false}))).wait();

      setDoc(doc(collection(props.db, 'stores'), store), {
        code: store,
        name: description,
        services: [],
        cards: {},
        users: {
          current: 1,
          limit: 1,
        },
        notifications: {
          current: 0,
          limit: 0,
        },
        tokens: 0,
      });

      const password = generatePassword();
      const token = await fetch(env.AUTH0_BASEURL+'oauth/token', {
        headers: {
          'content-type':'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          client_id: env.AUTH0_CLIENT,
          client_secret: env.AUTH0_SECRET,
          audience: env.AUTH0_AUDIENCE,
          grant_type: 'client_credentials',
        }),
      }).then((res) => res.json());
      await fetch(env.AUTH0_AUDIENCE+'users', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token.token_type+' '+token.access_token,
        },
        method: 'post',
        body: JSON.stringify({
          email: store+'@moonycard.it',
          password: 'Password123$',
          nickname: store,
          email_verified: true,
          user_metadata: {
            key: vendorWallet.privateKey,
            wallet: vendorWallet.address,
            store: store,
          },
          app_metadata: {
            role: 'admin',
          },
          connection: 'Username-Password-Authentication',
        }),
      });
      await fetch(env.AUTH0_AUDIENCE+'users', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token.token_type+' '+token.access_token,
        },
        method: 'post',
        body: JSON.stringify({
          email: email,
          password: password,
          nickname: email.split('@')[0],
          email_verified: true,
          user_metadata: {
            key: vendorWallet.privateKey,
            wallet: vendorWallet.address,
            store: store,
          },
          connection: 'Username-Password-Authentication',
        }),
      }).then((res) => res.json());

      await emailjs.send(env.EMAILJS_SERVICE_ID, env.EMAILJS_TEMPLATE_ID, {
        store: description,
        email: email,
        password: password
      }, env.EMAILJS_KEY);

      await wallet.sendTransaction({
        to: vendorWallet.address,
        value: ethers.utils.parseEther('0.01'),
      })

      setDescription('');
      setMemberLimit(1000);
      setEmail('');
      setMessage('Negozio registrato con successo.');
    }
    setLoading(false);
  };

  const generatePassword = () => {
    const lowerCasedAlphabets = [..."abcdefghijklmnopqrstuvwxyz".split("")];
    const upperCasedAlphabets = lowerCasedAlphabets.map((alphabet) => alphabet.toUpperCase());
    const numbers = [..."1234567890".split("")];
    const symbols = [..."!#$%&@*".split("")];
    const password = [];
    for (let i=0; i<5; i++) {
      password.push(lowerCasedAlphabets[Math.floor(Math.random() * lowerCasedAlphabets.length)]);
    }
    for (let i=0; i<3; i++) {
      password.push(upperCasedAlphabets[Math.floor(Math.random() * upperCasedAlphabets.length)]);
    }
    for (let i=0; i<2; i++) {
      password.push(numbers[Math.floor(Math.random() * numbers.length)]);
    }
    for (let i=0; i<2; i++) {
      password.push(symbols[Math.floor(Math.random() * symbols.length)]);
    }
    return password.sort(() => Math.random() - 0.5).join('');
  };

  return (
    <section id="register">
      <div className="affiliation-section">
        <h2 className="affiliation-title">Registra Negozio</h2>
        <div className="mt-5">
          <label className="mt-2">Nome Negozio</label>
          <input className="affiliation-input" disabled={loading} type="text" value={description} onChange={e => {setDescription(e.target.value); setMessage('');}} required />
          <label className="mt-2">Email</label>
          <input className="affiliation-input" disabled={loading} type="email" value={email} onChange={e => {setEmail(e.target.value); setMessage('');}} required />
          <label className="mt-2">Limite Tessere</label>
          <input className="affiliation-input" disabled={loading} type="number" min="100" step="100" value={memberLimit} onChange={e => {setMemberLimit(e.target.value); setMessage('');}} required />
          <div className="text-center mt-4">
            <p>{message}</p>
            {!loading && <button className="btn btn-lg item-button-success mt-3" onClick={ () => register() }>Registra</button>}
            {loading && <div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
