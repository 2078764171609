import { useState, useEffect } from "react";
import env from "react-dotenv";
import { useOutletContext } from "react-router-dom";
import { collection, doc, deleteDoc } from "firebase/firestore";
import { ethers } from "ethers";
import { affiliationResolverContractAbi } from "./data/affiliationResolver";

function Home(props) {

  const [page, setPage] = useState(1);
  const [range, setRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const [loading, setLoading] = useState(false);

  const [wallet] = useOutletContext();

  const removeStore = async (store) => {
    setLoading(true);
    if (wallet.address === env.OWNER_ADDRESS) {
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      await (await contract.removeStore(ethers.utils.formatBytes32String(store))).wait();
      deleteDoc(doc(collection(props.db, 'stores'), store));
    }
    setLoading(false);
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const contract = new ethers.Contract(env.RESOLVER_ADDRESS, affiliationResolverContractAbi, wallet);
      const storeList = await contract.getStores();
      const currentStores = [];
      for (const item of storeList) {
        const code = ethers.utils.parseBytes32String(item);
        const store = await fetch(env.AUTH0_API_SERVER+"store-info/"+code, {
          headers: {
            "Content-Type":"application/json",
          },
        }).then((res) => res.json());
        store.code = code;
        currentStores.push(store);
      }
      const range = [];
      for (let i = 1; i <= Math.ceil(currentStores.length / 15); i++) {
        range.push(i);
      }
      setRange([...range]);
      const slicedData = currentStores.slice((page - 1) * 15, page * 15);
      setSlice(slicedData);
      if (slicedData.length < 1 && page !== 1) {
        setPage(page - 1);
      }
      setLoading(false);
    };
    initData();
  }, [page, setPage, setRange, setSlice, wallet]);

  return (
    <section id="register">
      <div className="affiliation-section">
        <h2 className="affiliation-title">Elenco Negozi</h2>
        {!loading && <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Codice</th>
                <th scope="col"></th>
              </tr>
            </thead>
            {slice.length > 0 && <tbody>
              {slice.map((el, k) => (
                <tr key={k}>
                  <td>{el.name}</td>
                  <td>{el.code}</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <a className="btn btn-sm item-button" href={'/store/' + el.code}>Gestisci</a>
                      <button className="btn btn-sm item-button-danger" onClick={() => removeStore(el.code)}>Elimina</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>
        </div>}
        {!loading && slice.length === 0 && <span>Nessun negozio presente</span>}
        {!loading && <div className="text-center mt-5">
          {range.map((el, index) => (
            (page === el || page === el-1 || page === el+1 || index === 0 || index === range.length-1) && <button key={index} className={page === el ? 'btn item-button' : 'btn item-button-inverse'} onClick={() => setPage(el)}>{(page === el+1 && index !== 0) || (page === el-1 && index !== range.length-1) ? '...' : el}</button>
          ))}
        </div>}
        <div className="text-center">
          {loading && <div className="spinner-border mt-5" style={{width: '10rem', height: '10rem',}}></div>}
        </div>
      </div>
    </section>
  );
}

export default Home;
